<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
  },
  data() {
    return {
      salaryItems: [],
      levelThreeAccounts: [],
      branch: {},
      branchItem: {
        isNew: 1,
        item: {},
        code: null,
        account: null,
      },
    };
  },
  methods: {
    selectedAccount(account) {
      this.http
        .post("level-three-chart-of-accounts-copy-1/accounts-setup/search", {
          search: account,
        })
        .then((res) => {
          if (res.status) {
            this.levelThreeAccounts = res.data.map((a) => {
              return this.$i18n.locale == "ar"
                ? a.id + "-" + a.name + "(" + a.code + ")"
                : a.id + "-" + a.name_en + "(" + a.code + ")";
            });
          }
        });
    },
    addItem() {
      console.log(this.branchItem);
      this.http
        .post("salary-items-branches", {
          branch_id: parseInt(this.$route.params.id),
          branchItem: this.branchItem,
        })
        .then((res) => {
          if (res.status) {
            this.branchItem = {
              isNew: 1,
              item: {},
              code: null,
              account: null,
            };
            this.$router.push("/salary-items");
          }
        });
    },
    getBranch() {
      this.http.get("branches/" + this.$route.params.id).then((res) => {
        this.branch = res.data;
      });
    },
    get() {
      this.http
        .post("salary-items-branches/get-all-items-except", {
          branch_id: parseInt(this.$route.params.id),
        })
        .then((res) => {
          this.salaryItems = res.data;
        });
    },
  },
  computed: {
    filteredAccounts() {
      return this.levelThreeAccounts.filter((account) => {
        return account;
      });
    },
  },
  created() {
    this.getBranch();
    this.get();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="row align-items-center">
        <div class="d-flex align-items-center mb-4">
          <router-link :to="`/salary-items`">
            <h4 class="m-0">
              {{ $t("salaryItems.title") }}
            </h4>
          </router-link>
          <div class="mx-2">\</div>
          <div>
            {{
              $t("salaryItems.add_branch_item") +
              ` (${$i18n.locale == "ar" ? branch?.name : branch?.name_en})`
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row p-2" style="background-color: rgb(239, 237, 237)">
              <form class="mb-3" @submit.prevent="addItem">
                <div class="mb-3 mt-3">
                  <label for="exampleInputPassword1" class="form-label">
                    {{ $t("salaryItems.select_item") }}
                  </label>
                  <select
                    required
                    v-model="branchItem.item"
                    class="form-select"
                  >
                    <option
                      :value="item"
                      v-for="item in salaryItems"
                      :key="item"
                    >
                      {{ $i18n.locale == "ar" ? item.name : item.name_en }}
                    </option>
                  </select>
                </div>
                <div class="mb-3">
                  <label for="">{{ $t("salaryItems.qs") }}</label
                  ><br />
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      v-model="branchItem.isNew"
                      value="1"
                      name="flexRadioDefault"
                      checked
                      id="flexRadioDefault1"
                    />
                    <label class="form-check-label" for="flexRadioDefault1">
                      {{ $t("salaryItems.new") }}
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      value="0"
                      v-model="branchItem.isNew"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                    />
                    <label class="form-check-label" for="flexRadioDefault2">
                      {{ $t("salaryItems.exist") }}
                    </label>
                  </div>
                </div>
                <div class="mb-3" v-if="parseInt(branchItem.isNew)">
                  <label for="">Code</label>
                  <input
                    v-model="branchItem.code"
                    required
                    type="number"
                    class="form-control"
                  />
                </div>

                <div v-if="!parseInt(branchItem.isNew)" class="mb-3">
                  <label for="">{{ $t("salaryItems.searchAccount") }}</label
                  ><br />
                  <AutoComplete
                    @keyup="selectedAccount(branchItem.account)"
                    v-model="branchItem.account"
                    :suggestions="filteredAccounts"
                    @complete="branchItem.account = $event.query"
                    style="
                      color: #333 !important;
                      border-radius: 10px !important;
                    "
                  />
                </div>

                <hr class="mt-2" />
                <div
                  class="float-end"
                  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                >
                  <router-link to="/salary-items">
                    <button
                      style="margin-inline-end: 4px"
                      type="button"
                      class="btn btn-secondary"
                    >
                      {{ $t("popups.cancel") }}
                    </button>
                  </router-link>
                  <button type="submit" class="btn btn-primary">
                    {{ $t("popups.add") }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped></style>
